@mixin media-xs {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

// BEGIN: Desktop first media queries
@mixin media-xsm {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin media-sm {
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    @content;
  }
}
@mixin media-md {
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    @content;
  }
}
@mixin media-lg {
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    @content;
  }
}
@mixin media-xl {
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1365.98px) {
    @content;
  }
}
// END: Desktop first media queries
