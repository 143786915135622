.Menu {
  border-radius: 8px;
  box-shadow: 10px 10px 40px 0 var(--color-mystic);
  width: 200px;
}

.MoveIcon path {
  fill: var(--primary-text-color);
}

.ArchiveToastWrapper {
  position: relative;

  svg {
    position: relative;
    z-index: 1;
  }
}

.ArchiveToast {
  all: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 43px;

  * {
    all: unset;
    color: var(--color-torch-red) !important;
    text-transform: capitalize;
  }
}
