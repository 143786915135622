.wrapper {
  position: relative;
  padding-bottom: 110px;
  height: 255px;
}

.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.79;
  color: var(--primary-text-color);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eff3f7;
  background-color: #fff;
  width: 284px;
  margin: 0 auto;
}

.imageWrapper {
  width: 325px;
  height: 180px;
  position: absolute !important;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.plusIcon {
  fill: var(--primary-button-color);
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: baseline;
  margin: 0 4px;
}
