.Button {
  border-radius: 3px;
  padding: 7px 10px;
  width: auto;
  height: 32px;
}

.Text {
  margin-left: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-top: 1px;
}

.AddButton {
  min-width: 106px;
}

.RoundedButton {
  border-radius: 21px;
  height: auto;
  padding: 13px;
  text-align: center;

  span {
    margin: 0;
  }
}
