.wrapper {
  width: 100%;
  overflow: hidden;
  padding: 10px 15px;
}

.Avatar {
  margin-right: 10px;
}

.AvatarSpammer {
  background-color: #d3093f !important;
}

.detailsContainer {
  display: flex;
  overflow: hidden;
}

.textContent {
  padding: 1px 0;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.titlePrimary {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  color: var(--primary-text-color);
}

.titlePrimary.spammer {
  color: var(--primary-text-error-color);
}

.titleSecondary {
  font-size: 13px;
  line-height: 1.23;
  color: var(--secondary-text-color);
}

.titlePrimary,
.titleSecondary {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actions {
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  background-color: #dde0fb;
  padding: 2px 3px;
  border-radius: 4px;
}

.wrapper:hover .actions {
  opacity: 1;
  visibility: visible;
}

.actions a {
  background-color: #5f48e9;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.actions svg {
  fill: #fff;
  width: 100%;
  height: 16px;
}

.tags {
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: 7px;
  align-items: flex-start;
}

.tagItem {
  background-color: #ff6d39;
  color: #fff;
  font-size: 10px;
  text-align: center;
  border-radius: 14px;
  padding: 1px 10px;
}

.tags span {
  margin-left: 6px;
  font-size: 12px;
  color: var(--primary-text-color);
  font-weight: 400;
}

.assignSection {
  padding: 5px 15px;
}

.assignSectionDivider {
  background-color: #8387a5;
  opacity: 0.3;
  margin-bottom: 8px;
  height: 1px;
  width: 100%;
}

.assignSectionSelect {
  display: flex;
  align-items: center;
}
.assignSectionSelect > div {
  display: flex;
}
.assignSectionSelect .assignSectionLabel {
  font-size: 12px;
  font-weight: 400;
  color: #8387a5;
  margin-right: 8px;
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}
