.ColumnForm {
  padding: 10px;
}

.NewColumnAdd {
  border-radius: 4px;
  background-color: #5f48e9;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13px;
  margin-right: 15px;
  transition: 0.3s;
  color: #fff !important;
  box-shadow: none;
}
.NewColumnAdd:hover {
  background-color: #5f48e9;
}
.NewColumnClose {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13px;
}

.NewColumnClose :global(.MuiButton-label) {
  color: #8387a5;
}
.NewColumnAdd:disabled {
  background-color: #5f48e9;
  opacity: 0.3;
}

.ColumnFormInput {
  border: 1px solid #5f48e9;
  border-radius: 6px;
  width: 100%;
}

.ColumnFormInput {
  border: 1px solid #5f48e9;
  border-radius: 6px;
  width: 100%;
  z-index: 2;
}

.ColumnForm input {
  padding: 9px;
}
.ColumnForm input::placeholder {
  font-size: 11px;
}

.ColumnFormActions {
  margin-top: 10px;
}
