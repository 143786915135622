.divider {
  background-color: var(--primary-border-color);
}

.deleteButton:hover {
  color: #5f48e9;
}
.deleteButton {
  background-color: #fff;
  transition: 0.3s;
  color: #8387a5;
  font-size: 11px;
  border: none;
  text-transform: uppercase;
  margin-left: 5px;
  cursor: pointer;
}
.addButton {
  color: #5f48e9;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}
.formInput {
  width: 100%;
  resize: none;
  padding: 10px;
}
.formInput textarea {
  font-size: 13px;
  color: var(--primary-text-color);
  padding: 7px 5px;
  border-radius: 4px;
  border: 1px solid #5f48e9;
}
.formActions {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 10px;
  justify-content: flex-end;
}
.successButton {
  margin-left: 30px;
  padding: 5px 30px 6px 29px;
  border-radius: 16px;
  background-color: #5f48e9;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  max-width: 90px;
  text-transform: capitalize;
}
.closeButton {
  text-transform: capitalize;
  font-weight: 400;
  color: #8387a5 !important;
  font-size: 13px;
}
.notesWrapper {
  margin-bottom: 10px;
}
.tasksWrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}
.tasksListContainer {
  margin-top: 15px;
}
.taskItem {
  border-radius: 6px;
  border: solid 1px #eff3f7;
  padding: 20px 20px;
}
.taskContent {
  display: flex;
  align-items: flex-start;
}
.taskTitle {
  font-size: 17px;
  color: var(--primary-text-color);
  margin: -5px 0 0 0;
}
.taskHelpers {
  margin-top: 5px;
  display: flex;
  font-size: 11px;
  align-items: center;
}
.taskHelpers > div {
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 6px;
  background-color: #fef2e2;
  color: #f7a305;
  display: flex;
  align-items: center;
}
.taskHelpers > div svg {
  margin-right: 5px;
}
.taskMainInfo {
  margin-left: 20px;
}
.taskInfo {
  margin-top: 10px;
}
.taskDescription {
  font-size: 13px;
  color: #8b90a3;
}
.taskCheckbox {
  color: #c4c3c5;
  width: 20px;
  height: 20px;
}
.taskCheckbox:global(.Mui-checked) {
  color: #fff;
  background-color: #19e35f;
}
.taskImportant {
  color: #8387a5;
  margin-left: 10px;
  padding: 0;
}
.taskImportantTrue {
  color: #6c04e4;
}
.formWrapper {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.DialogTitle h2 {
  color: var(--primary-text-color) !important;
}
