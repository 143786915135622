.fieldsWrapper {
  margin-top: 30px;
}
.fieldsInfo {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 30px);
}
.fieldLabel {
  color: #8387a5;
  font-size: 12px;
  text-transform: capitalize;
}
.fieldText {
  font-size: 15px;
  color: var(--primary-text-color);
}
.fieldActionButton {
  background-color: #efecfd;
  border-radius: 8px;
  padding: 8px;
  margin-left: 8px;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: var(--primary-button-color);

    svg,
    svg path {
      fill: var(--color-white) !important;
    }
  }
}
.fieldWrapper {
  position: relative;
  transition: 0.2s;
  z-index: 0;
  padding: 10px 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
}

.fieldWrapper:hover::after {
  background-color: #f7f8f9;
}
.fieldWrapper:hover .fieldEditButton {
  opacity: 1;
}

.fieldEditButton {
  opacity: 0;
  padding: 4px 5px;
  transition: 0.2s;
  color: var(--primary-text-color);

  margin-left: 5px;
}
.fieldEditButton svg {
  width: 14px;
  height: 14px;
}

.fieldWrapper::after {
  z-index: -1;
  content: '';
  width: calc(100% + 45px);
  height: 100%;
  position: absolute;
  top: 0;
  left: -20px;
  transition: 0.2s;
  background-color: transparent;
}

.EditPopover {
  border-radius: 12px;
  padding: 20px;
  width: 360px;
}

.Title {
  font-size: 14px;
  padding-bottom: 15px;
}

.InputWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 15px;
  justify-content: flex-end;
}

.fieldsAddButton {
  color: #8387a5;
  transition: 0.2s;
  font-size: 15px;
  text-transform: capitalize;
}
.fieldsAddButton:hover {
  background-color: transparent;
}
