.wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: auto;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.title {
  color: var(--primary-text-color);
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  margin: 0 0 8px;
}

.seeAllButton {
  font-size: 13px;
  font-weight: 400;
  color: var(--primary-button-color);
  cursor: pointer;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.seeAllButton:hover {
  text-decoration: underline;
}

.addButton {
  font-size: 15px !important;
  text-transform: capitalize;
}
