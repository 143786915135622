@import 'src/styles/media';

.heading {
  border-bottom: 1px solid var(--color-mystic);
  padding-bottom: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &.NoBorder {
    border-bottom: none;
  }

  @include media-sm {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  h1 {
    margin: 0;
    color: var(--primary-text-color);
    font-size: 24px;
    font-weight: 500;

    @include media-sm {
      font-size: 18px;
    }
  }

  p {
    width: 100%;
    margin: 0 0 -5px;
    font-size: 12px;
    color: var(--secondary-text-color);

    @include media-sm {
      margin: 0;
    }
  }
}
