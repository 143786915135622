.ColumnForm {
  min-width: 365px;
  max-width: 365px;
}

.ColumnFormInput {
  border: 1px solid #5f48e9;
  border-radius: 6px;
  width: 100%;
  height: 41px;
}
.ColumnForm input {
  padding: 9px;
}
.ColumnForm input::placeholder {
  font-size: 11px;
}
.ColumnFormContainer {
  padding: 7px;
  margin: 0;
  box-shadow: 10px 10px 40px 0 #d8dfea;
}
.ColumnFormActions {
  display: flex;
  margin-top: 10px;
}

.ColumnFormLoaderPlace {
  position: relative;
  margin-right: 16px;
}

.ColumnFormActionsSpinnerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ColumnFormActionsSpinner {
  display: block;
}

.saveButton {
  padding-left: 24px;
  padding-right: 24px;
}

.saveButton.submitting span {
  opacity: 0;
}
