.rootWrapper {
  z-index: 1000 !important;
}

.dialogWrapper {
  height: auto;
  border-radius: 8px;
  box-shadow: 10px 10px 40px 0 var(--color-mystic);
}

.container {
  padding: 10px 15px 0;
  width: 350px;
}

.containerTagsDialog {
  width: 360px;
}

.containerTagsDialog .tagItem {
  padding: 10px 0;
}

.tagItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff3f7;
  cursor: pointer;
}

button.tagItem {
  border: unset;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff3f7;
  cursor: pointer;
  background: none;
  width: 100%;
  text-align: left;
}

.containerTagsDialog .tagItem {
  padding: 10px 0;
}

.tagColor {
  border-radius: 5px;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  border: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.tagColor svg {
  fill: #fff;
}

.tagNameInput {
  margin-left: 15px;
  width: 100%;
  font-size: 13px;
}

.tagNameInput input {
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
}

.tagNameInput input:focus {
  border-bottom: 1px solid #5f48e9;
}

.addTagButton {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 300;
  color: var(--primary-text-link-color);
  width: 100%;
  justify-content: flex-start;
  height: 47px;
}

.spinnerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(255 255 255 / 48%);
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
