@import '../../../../../../../styles/media.scss';

.Wrapper {
  & > div {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #8387a5 !important;
    cursor: pointer;

    &:last-child {
      path {
        fill: #8387a5 !important;
      }
    }
  }
}

.DeleteDialogDescription {
  white-space: pre-wrap;
}

.container {
  width: 390px;
  border-radius: 20px;
  box-shadow: 10px 10px 40px 0 rgba(166, 158, 204, 0.1);
  text-align: center;
  padding: 15px 20px 30px;
}

.title {
  padding: 0;

  h2 {
    text-align: left;
    font-weight: 400;
    font-family: var(--font-poppins);
  }
}

.DialogContent {
  display: block;
  flex: unset;
  padding: 17px 0 0;
}

.Input {
  width: 100%;
}

.Buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 25px;
  gap: 15px;
}

.Desktop {
  display: block;

  @include media-sm {
    display: none !important;
  }
}

.Mobile {
  display: none;

  & > div {
    display: none;
  }

  @include media-sm {
    display: block;

    & > div {
      display: flex;
    }
  }
}

.DotsButton {
  display: flex;
  cursor: pointer;

  div {
    width: 5px;
    height: 5px;
    background-color: var(--color-manatee);
    border-radius: 50%;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.MobileMenu {
  width: 150px;
  padding: 10px 0;
}

.MobileButton {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    fill: #8387a5 !important;
  }

  &:last-child {
    svg {
      path {
        fill: #8387a5 !important;
      }
    }
  }
}
