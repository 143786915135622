@import 'src/styles/media';

.Column {
  width: 100%;
  max-width: 285px;
  min-width: 285px;
  background: var(--color-white);
  position: relative;
  left: 0;
  transition: all 200ms ease;
  will-change: width;

  &.Collapsed {
    width: 30px;
    min-width: unset;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-white);
      z-index: 999;
    }
  }
}

.SwitchButton {
  position: absolute;
  right: -13px;
  top: 91px;
  width: 26px;
  height: 26px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-primary);
  border-radius: 50%;
  cursor: pointer;
  z-index: 1002;
  display: block;

  &:before {
    content: '';
    position: absolute;
    border: 1px solid var(--primary-text-color);
    width: 5px;
    height: 5px;
    border-left: none;
    border-bottom: none;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 1001;
    top: 50%;
    left: calc(50% - 4px);
  }

  &.ButtonExpanded {
    &:before {
      transform: rotate(225deg) translate(-50%, -50%);
      top: 23%;
    }
  }
}
