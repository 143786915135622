.wrapper {
  width: 20px;
  height: 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: inherit;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--secondary-text-color);
  transition: border-color 0.2s, background-color 0.2s;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.button:not(.completed):not(:disabled):hover {
  border-color: var(--primary-button-color);
}

.button svg {
  transition: opacity 0.2s;
  opacity: 0;
  height: 14px;
  width: 18px;
}

.button.completed {
  border-color: #19e35f;
  background-color: #19e35f;
  opacity: 1;
  color: #fff;
  transition: opacity 0.2s;
}

.button.completed:not(:disabled):hover {
  opacity: 0.7;
}

.button.completed svg {
  opacity: 1;
}

.button:disabled {
  opacity: 0.6;
  cursor: default;
}
