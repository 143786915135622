.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 115px);
  align-items: center;
  justify-content: center;

  span {
    margin-top: 27px;
    color: #5f48e9;
    font-weight: 500;
    font-size: 15px;
    user-select: none;
  }
}
