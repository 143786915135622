.wrapper {
  border-radius: 6px;
  border: solid 1px #eff3f7;
  background-color: #fff;
}

.head {
  padding: 16px;
  display: flex;
  align-items: baseline;
}

.expandMore {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 20px;
  width: 20px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  margin: 0 20px 0 0;
  padding: 0;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--secondary-text-color);
}

.expandMore.isExpanded svg {
  transform: scale(1, -1);
}

.expandMore svg {
  fill: inherit;
  width: 10px;
}

.number,
.createdAt,
.title,
.total {
  font-size: 15px;
  font-weight: 400;
  color: var(--primary-text-color);
}

.number {
  margin-right: 32px;
}

.createdAt {
  color: var(--secondary-text-color);
  margin-right: 65px;
}

.title {
  margin-right: 32px;
}

.draft {
  padding: 2px 10px;
  border-radius: 4px;
  background-color: #f2f3f6;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: var(--primary-text-color);
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.total {
  font-weight: 500;
  margin-left: auto;
}

.total span {
  font-weight: 400;
}

.items {
  max-height: 0;
  transition: max-height 0.45s;
  overflow: hidden;
}

.itemsContent {
  border-top: 1px solid #8387a54d;
  padding: 20px 28px;
}

.items table {
  width: 100%;
}

.items table th,
.items table td {
  font-size: 15px;
  font-weight: 400;
  padding: 8px 16px;
}

.items table th {
  color: var(--secondary-text-color);
}

.items table td:not(:first-child) {
  width: 25%;
}

.items table tr td:first-child {
  opacity: 0.5;
}

.items .totals {
  padding-top: 16px;
  margin-top: 10px;
  border-top: 1px solid #8387a54d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.items .totals div {
  font-size: 15px;
  font-weight: 500;
  color: var(--primary-text-color);
}

.items .totals div:not(:last-child) {
  margin-bottom: 13px;
}

.items .totals div span {
  color: var(--secondary-text-color);
  font-weight: 400;
  margin-right: 6px;
}

.bottom {
  padding: 0 16px 14px;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #8387a54d;
  padding-top: 11px;
}

.validity {
  font-size: 13px;
  font-weight: 400;
  color: var(--secondary-text-color);
}

.validity span {
  margin-right: 10px;
  display: inline-block;
  vertical-align: sub;
}

.validity span svg {
  width: 16px;
  fill: var(--secondary-text-color);
  display: block;
}

.actions {
  display: flex;
  align-items: center;
}

.actionButton {
  align-self: center;
  height: 20px;
  width: 20px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  margin: 0 0 0 30px;
  padding: 0;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--secondary-text-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.actionButton .buttonIcon {
  fill: var(--secondary-text-color);
  display: block;
}

.actionButton:hover .buttonIcon {
  fill: var(--primary-button-color);
}

.editAction .buttonIcon {
  width: 18px;
}

.duplicateAction .buttonIcon {
  width: 17px;
}

.downloadAction .buttonIcon {
  width: 14px;
}

.deleteAction .buttonIcon {
  width: 13px;
}

.buttonSpinner {
  position: absolute;
}
