.boardContainer {
  width: 100%;
  display: flex;
  flex-flow: column;
  background-color: #f6f8fb !important;
  padding: 0 !important;
  overflow: auto;
}

.column:not(:last-child) {
  margin-right: 10px;
}
.columnCardList {
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
}
.columnCardList > div[data-rbd-droppable-context-id] {
  height: 100%;
}

.boardWrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
}
.boardSearch {
  background-color: #fff;
  border-radius: 6px;
  padding: 8px 10px;
  width: 113px;
  height: 32px;
  color: #8387a5;
  border: 1px solid #d8dfea;
  font-size: 13px;
  font-family: Poppins, sans-serif;
}

.boardSearch svg {
  fill: #d8dfea;
  margin-right: 5px;
  width: 15px;
}

.filterBoard :global(.MuiButton-label) {
  text-transform: capitalize;
  font-size: 13px;
  color: #8387a5;
  font-weight: 400;
}

.filterBoardActive :global(.MuiButton-label) {
  color: #001847;
  font-weight: 600;
}
.filtersBoard {
  margin-left: 5px;
}
.filterBoard {
  margin: 0 7px;
}
.filtersBoard :global(.MuiButtonGroup-groupedTextHorizontal:not(:last-child)) {
  border-right: none;
}
.switcher {
  width: 90px;
  height: 45px;
  border: solid 1px #dfe4e8;
  margin-left: 10px;
  border-radius: 27px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
}
.switcher .iconWrapper {
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  fill: #8387a5;
}
.hoverIcon {
  transition: 0.2s;
}
.hoverIconFirst {
  left: 4px;
}
.hoverIconSecond {
  left: calc(100% - 40px);
}
.switcher .hoverIcon {
  position: absolute;
  fill: #fff;
  background-color: #5f48e9;
}
/* .switcher .iconWrapper:first-child {
  fill: #fff;
  background-color: #5f48e9;
}

.switcher .iconWrapper:last-child {
  fill: #8387a5;
} */
/* .switcherActive .iconWrapper:last-child {
  fill: #fff;
  background-color: #5f48e9;
}
.switcherActive .iconWrapper:first-child {
  background-color: transparent;
  fill: #8387a5;
} */
/* .switcherTrack {
  display: none;
}
.switchIcon {
  top: 4px;
  left: 4px;
  padding: 10px;
  background-color: #5f48e9;
}
.switchIcon:hover {
  background-color: #5f48e9;
} */
.switchWrapper {
  display: flex;
  font-size: 13px;
  color: #8387a5;
  align-items: center;
  margin-left: 150px;
}
.switchWrapper span {
  user-select: none;
}

.BoardCard {
  width: 284px;
  max-width: 284px;
  margin: 0 0 10px;
  display: flex;
  align-items: flex-start;
}

.BoardCardInner {
  width: 100%;
}

.BoardCardMenuOpen {
  background-color: #f2f2ff !important;
}

.ContactCard {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.columnsWrapper {
  display: flex;
  padding: 16px 15px 0;
  flex: 1;
}

.columnsWrapperRow {
  flex-shrink: 0;
  display: flex;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-flow: column;
}
.columnsWrapperRow .columnCardList > div {
  width: 100%;
}
.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
  padding: 13px 30px 13px 20px;
  min-height: auto;
  background: var(--color-white);
  border-left: 1px solid #eff3f7;
  z-index: 1299 !important;
  position: sticky;
  top: 0;
  left: 0;
}

.toolbarLeft {
  display: flex;
  align-items: center;
}

.createButton {
  margin-left: auto;
  padding: 3px 4px 3px 15px;
  border-radius: 25px;
}

.createButton .createButtonPlus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin: 0 0 0 6px;
  border-radius: 50%;
  background-color: #5946ce;
  font-size: 20px;
  font-weight: 300;
  transition: background-color 0.25s;
}

.createButton:hover .createButtonPlus {
  background-color: #9a89ff;
}

.laneActionButton {
  color: #001847;
}

.laneActionButton:hover div div {
  background-color: var(--primary-button-color);
}

.laneActionButton:hover svg,
.laneActionButton:hover svg path {
  fill: var(--primary-button-color) !important;
}

.laneActionButton:disabled div div {
  background-color: var(--secondary-text-color) !important;
}

.columnTitle {
  font-size: 18px;
  color: #001847;
  padding: 10px 0;
}
.columnMenu {
  text-transform: capitalize;
}
.columnMenu svg {
  width: 10px;
  margin-right: 12px;
}
.columnMenu :global(.MuiMenuItem-root) {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 13px;
  color: var(--primary-text-color);
}
.ColumnForm {
  min-width: 365px;
  max-width: 365px;
}
.ColumnFormInput {
  border: 1px solid #5f48e9;
  border-radius: 6px;
  width: 100%;
}
.ColumnForm input {
  padding: 9px;
}
.ColumnForm input::placeholder {
  font-size: 11px;
}
.ColumnFormContainer {
  padding: 7px;
  margin: 0 20px;
}
.ColumnFormActions {
  display: flex;
  margin-top: 10px;
}

.ColumnFormLoaderPlace {
  position: relative;
}

.ColumnFormActionsSpinnerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ColumnFormActionsSpinner {
  display: block;
}

.NewColumnAdd:disabled {
  background-color: #5f48e9;
  opacity: 0.3;
}
.NewColumnAdd {
  border-radius: 16px;
  background-color: #5f48e9;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13px;
  margin-right: 15px;
  transition: 0.3s;
  color: #fff !important;
}
.NewColumnAdd:hover {
  background-color: #5f48e9;
}

.NewColumnAdd.NewColumnAddHidden {
  opacity: 0;
  visibility: hidden;
}

.NewColumnClose {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13px;
}

.NewColumnClose :global(.MuiButton-label) {
  color: #8387a5;
}
.colorWrapper {
  padding: 25px;
}
.colorsTitle {
  display: flex;
  font-size: 15px;
  color: var(--primary-text-color);
}
.colorsTitle svg {
  margin-right: 20px;
}
.colorPalette {
  margin-top: 10px;
  display: flex;
  flex-flow: row wrap;
  max-width: 255px;
}
.colorPalette .colorPaletteItem {
  border: 0;
  width: 30px;
  height: 30px;
  margin-top: 15px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 5px 0 rgba(63, 81, 180, 0.25);
}
.colorPalette .colorPaletteItem svg {
  color: #fff;
}
.colorPalette .colorPaletteItem:not(:nth-child(6n + 1)) {
  margin-left: 15px;
}
.ColorActions {
  margin-top: 30px;
}
.column {
  display: flex;
  flex-flow: column;
  height: max-content;
  transition: 0.25s background-color;
  background-color: rgba(216, 223, 234, 0.4);
  border-radius: 8px;
  min-width: 300px;
  margin-top: 10px;
  padding-top: 10px;
}
.columnsWrapperRow {
  padding: 0 50px;
}
.columnsWrapperRow .column {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 28px 0 rgba(166, 158, 204, 0.1);
  margin-bottom: 30px;
  padding: 15px 25px;
}

.columnsWrapperRow .card {
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: none;
}
.columnsWrapperRow .card .rowCardDescription {
  flex-flow: row nowrap;
  display: flex;
  margin-left: 20px;
  align-items: center;
}
.columnsWrapperRow .card .rowCardDescription > :global(.MuiTypography-root) {
  min-width: 200px;
}
.columnsWrapperRow .column:not(:last-child) {
  margin-right: 0;
  border: none;
}
.columnTableHeader {
  display: flex;
  width: 100%;
  background-color: #f5f6fa;
  border-radius: 8px;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 30px;
}
.columnTableHeader span {
  color: #8387a5;
  font-size: 12px;
}
.columnTableHeader span:first-child {
  margin-right: 20px;
  min-width: 25px;
}
.columnTableHeader span.headerItemName {
  min-width: 200px;
}
.columnTableHeader span.headerItemTags {
  min-width: 200px;
}
.columnTableHeader span.headerItemStatus {
  min-width: 200px;
}
.columnTableHeader span.headerItemNotes {
  min-width: 400px;
}
.columnsWrapperRow .cardLastCalls {
  border: none;
}

.columnDrag {
  background-color: rgb(215, 217, 226, 0.5);
}
.proposalColumn {
}
.columnHeaderButton {
  font-size: 13px;
  width: 30%;
  text-align: right;
  transition: 0.25s opacity;
  display: flex;
}

.columnDrag .columnHeaderButton {
  opacity: 0;
}

.columnsWrapperRow .columnDrag {
  height: 75px !important;
}
.tagsWrapper {
  display: flex;
}
.tagsWrapper p {
  display: flex;
  align-items: center;
}
.tagsWrapper > * {
  margin-right: 10px;
}
.rowActions {
  display: flex;
  font-size: 12px;
  color: #8387a5;
  align-items: center;
}
.rowActions > div {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.rowActions span {
  font-size: 12px;
  margin-left: 5px;
}
.rowActions svg {
  width: 15px;
}
.rowActions > div:hover span {
  color: #5f48e9;
}
.rowActions > div:hover svg path {
  fill: #5f48e9;
}
.columnHeader {
  position: sticky;
  z-index: 1001;
  padding-left: 15px;
  top: -16px;
  left: 0;
  background-color: #e9edf3;
}
.editColumn {
  position: absolute;
  top: 50px;
  z-index: 10;
}

.addContactWrapper {
  max-width: 600px;
}
.emptyBoard {
  position: absolute;
  top: 71px;
  left: 0;
  z-index: 1;
}
.columnEmpty {
  min-height: 316px;
}
.colorIconButton {
  padding: 0;
}
.tableContainer {
  display: table;
  border-collapse: separate;
}

.tableContainer .columnTableHeader {
  display: table-header-group;
}

.tableContainer .tableBody {
  display: table-row-group;
  padding: 9px 0;
}

.tableContainer th {
  color: var(--secondary-text-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding: 18px 15px 15px;
}

.tableContainer tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableContainer tr th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tableContainer tr.isDragging {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.05);
}

.tableContainer td {
  padding: 9px 15px;
  font-size: 13px;
  background-color: #fff;
}

.tableContainer td.number,
.tableContainer td.title {
  color: var(--secondary-text-color);
}

.tableContainer td.sent {
  color: #2e374d;
}

.tableContainer td.total {
  font-weight: 500;
}

.tableContainer td.contextMenu {
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  width: 60px;
}

.searchIcon {
  width: 14px;
  height: 14px;
  fill: var(--primary-text-color);
}

.toolbarRight {
  display: flex;
  align-items: center;
}

.inviteTeammembersButton {
  margin-right: 20px;
}

.IconHover:hover div {
  background-color: var(--primary-text-color);
}
