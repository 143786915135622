.wrapper {
  min-height: 40px;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  font-size: 14px;
  position: sticky;
  left: 0;
  top: 0;

  a {
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }
  }

  .fixPaymentLink {
    color: #ffff00;
  }
}
