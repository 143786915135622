.MenuPaper {
  box-shadow: -10px 10px 40px 0 #d8dfea;
  width: 138px;
}

.MenuItem {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: #f2f2ff !important;
  }

  svg {
    width: 20px;
    stroke: #5f48e9;
  }
}

.label {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 2px 9px;
  text-align: center;
  border-radius: 4px;
  position: relative;
}

.labelBackground {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  border-radius: inherit;
}

.labelText {
  position: relative;
  z-index: 2;
}

.ArchiveSnackbar {
  border: solid 1px #5f48e9 !important;
  background-color: #efecfd !important;
  color: #5f48e9 !important;

  svg {
    fill: #5f48e9 !important;
  }
}

.ArchiveSnackbarUndo {
  all: unset;
  text-decoration: underline;
  font-size: 12px;
  color: #000;
  margin-left: auto;
  cursor: pointer;
}
