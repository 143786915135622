.List {
  overflow: hidden;
  margin-bottom: 10px;
  display: grid;
  max-width: 100%;
  grid-column-gap: 12px;
  grid-row-gap: 10px;

  &.OneFile {
    grid-template-columns: minmax(0, 1fr);
  }

  &.TwoFiles {
    @media screen and (max-width: 1200px) {
      grid-template-columns: minmax(0, 1fr);
    }

    @media screen and (min-width: 1507px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &.ThreeFiles {
    @media screen and (min-width: 2030px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &.FourFiles {
    @media screen and (min-width: 2560px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 1507px) {
    grid-template-columns: minmax(0, 1fr);
  }

  @media screen and (min-width: 1508px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: 1810px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (min-width: 2030px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media screen and (min-width: 2560px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
