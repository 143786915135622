.AddNoteOption {
  all: unset;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  width: 200px;
  max-width: 200px;
  position: relative;

  &:hover {
    background-color: #f2f2ff;
  }

  &:first-child svg path {
    fill: var(--color-black);
  }

  svg {
    max-width: 15px;
  }

  span {
    font-size: 11px;
    font-family: var(--font-poppins);
    color: var(--primary-text-color);
    margin-left: 13px;
  }

  input {
    cursor: pointer;
  }
}
