@import 'src/styles/media';

.wrapper {
  position: relative;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 30px;
  padding: inherit;
}
.paper {
  overflow: auto;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0 8px 28px 0 rgba(166, 158, 204, 0.1);
}
.avatarMenu {
  border-radius: 6px;
  margin-top: 60px;
}
.avatarMenuItem {
  font-size: 11px;
}
.backButtonContainer svg {
  width: 13px;
  margin-right: 5px;
}
.mainInfo {
  max-width: 400px;
  min-width: 400px;
  padding-right: 25px;
  border-right: solid 1px #eff3f7;
}
.backButtonContainer button {
  color: var(--primary-text-color);
  font-size: 13px;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background: none;
  padding: 0;
}
.backButtonContainer:hover button {
  color: #7967ec;
}
.container {
  padding: 20px;
  height: 100%;
  min-width: 800px;
}
.infoContainer {
  margin-top: 25px;
  display: flex;
  padding-bottom: 0;
  padding-bottom: 20px;
  overflow: hidden;
}
.hoverImage {
  padding: 15px;
  height: 60px;
  width: 60px;
  display: flex;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: #5f48e9;
  border-radius: 50%;
  position: absolute;

  @include media-xs {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    width: 40px;
  }
}
.image {
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 32px;
  position: relative;
  fill: #fff;
  max-width: 60px;
}
.image:hover .hoverImage {
  opacity: 1;
}
.image img {
  border-radius: 50%;
}
.previewInfo {
  margin-bottom: 30px;
  display: flex;

  flex-direction: column;
}
.contactTitles {
  margin-left: 25px;
}
.contactMainTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  margin: 0;
  font-size: 22px;
  cursor: pointer;
  max-width: 200px;
  color: var(--primary-text-color);

  @include media-xs {
    font-size: 19px;
  }
}
.contactJobTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  max-width: 200px;
  font-size: 16px;
  color: #8387a5;
}
.tag {
  color: #fff;
  font-size: 10px;
  padding: 0 10px;
  height: 16px;
  margin-right: 10px;
  border-radius: 9px;
  margin-top: 10px;
}
.tag:first-child {
  margin-left: 0;
}
.tagsContainer {
  display: flex;
  flex-flow: row wrap;
  margin-top: -6px;
}
.tagButton {
  cursor: pointer;
  margin-top: 12px;
  border: none;
  padding: 2px 10px;
  font-size: 11px;
  color: #5f48e9;
  background: #efecfd;
  border-radius: 9px;
}
.actionContainer {
  display: flex;
  margin-top: 30px;
}
.actionLink {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
}
.actionButtonContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  margin-right: 20px;
}
.actionButtonContainer:last-child {
  margin-right: 0;
}
.actionButton {
  width: 40px;
  height: 40px;
  background-color: #efecfd;
  padding: 10px;
}
.actionButton:hover {
  background-color: #5f48e9;
}
.actionButton:hover svg path {
  fill: #fff;
}
.actionLabel {
  font-size: 12px;
  color: #8387a5;
}
.tabsContainer {
  width: calc(100% - 400px);
  padding-left: 50px;
  padding-right: 40px;
  overflow: hidden;
}
.tab {
  color: #8387a5;
  padding: 0;
  min-width: 0;
  padding-right: 70px;
}
.tabLabel {
  align-items: start;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
}
.activeTab {
  color: #7967ec;
}
.tabIndicator {
  max-width: 30px;
  height: 3px;

  background-color: #7967ec;
}
.formInput {
  min-width: 250px;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #5f48e9;
  font-size: 15px;
  color: var(--primary-text-color);
}
.formInput:global(.Mui-disabled) {
  background-color: #eff3f7;
  border: 1px solid transparent;
}
.formInput:global(.Mui-disabled) input {
  opacity: 0;
}
.formLabel {
  font-size: 13px;
  color: #8387a5;
}
.editPreviewForm {
  padding: 25px;
}
.succeedButton {
  background-color: #5f48e9;
  border-radius: 4px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 400;
}
.cancelButton {
  margin-left: 30px;
  color: #8387a5;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 13px;
}
.fieldsWrapper {
  margin-top: 30px;
}
.fieldsInfo {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 30px);
}
.fieldLabel {
  color: #8387a5;
  font-size: 12px;
  text-transform: capitalize;
}
.fieldText {
  font-size: 15px;
  color: var(--primary-text-color);
}
.fieldActionButton {
  background-color: #efecfd;
  border-radius: 8px;
  padding: 8px;
  margin-left: 8px;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: var(--primary-button-color);

    svg,
    svg path {
      fill: var(--color-white) !important;
    }
  }
}
.fieldWrapper {
  position: relative;
  transition: 0.2s;
  z-index: 0;
  padding: 10px 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
}
.addButton {
  margin-top: 40px;
  color: #5f48e9;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}
.fieldValue {
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.fieldActions {
  display: flex;
}
.fieldEditButton {
  opacity: 0;
  padding: 4px 5px;
  transition: 0.2s;
  color: var(--primary-text-color);

  margin-left: 5px;
}
.fieldEditButton svg {
  width: 14px;
  height: 14px;
}
.fieldWrapper::after {
  z-index: -1;
  content: '';
  width: calc(100% + 45px);
  height: 100%;
  position: absolute;
  top: 0;
  left: -20px;
  transition: 0.2s;
  background-color: transparent;
}
.fieldWrapper:hover::after {
  background-color: #f7f8f9;
}
.fieldWrapper:hover .fieldEditButton {
  opacity: 1;
}
.fieldsAddButton {
  color: #8387a5;
  transition: 0.2s;
  font-size: 15px;
  text-transform: capitalize;
}
.fieldsAddButton:hover {
  background-color: transparent;
}
.contactFieldsFormWrapper {
  border-radius: 12px;
  min-width: 360px;
}
.contactFieldsForm {
  padding: 20px;
}
.contactFieldsFormActions {
  margin-top: 20px;
}
.contactFieldsFormActions button:first-child {
  margin-right: 20px;
}
.contactFieldsFormActionsSubmit {
  background-color: #5f48e9;
  text-transform: capitalize;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}
.contactFieldsFormActionsCancel {
  font-weight: 400;
  font-size: 13px;
  color: #818e94;
  text-transform: capitalize;
}
.formDeleteButton {
  padding: 0;
  margin-left: 20px;
  font-size: 13px;
  color: var(--primary-text-color) !important;
  text-transform: capitalize;
  font-weight: 400;
  background-color: transparent;
}
.formDeleteButton:hover {
  color: var(--primary-button-color) !important;
  background-color: transparent !important;
}
.formField {
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.contactFormTitle label,
.contactFormTitle label:global(.Mui-focused) {
  font-size: 13px;
  color: #818e94;
}
.contactFormTitle input {
  color: var(--primary-text-color) !important;
}
.formFieldInput {
  margin-bottom: 0;
}
.formDeleteButtonMargin {
  margin-top: 20px;
}
.hideDeleteButton {
  display: none;
}
.select {
  color: #8387a5;
  text-transform: capitalize;
  font-size: 13px;
}
.eventField {
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
}
.formSelect {
  font-size: 13px !important;
  color: #818e94 !important;
}

.formSelectItem {
  font-size: 11px;
}

.DefaultActionsButtonWrapper {
  all: unset;
  display: flex;
  flex-direction: column;

  &.Margin {
    margin-left: 20px;
  }

  span {
    text-align: center;
  }
}

.DefaultActionsButton {
  background-color: var(--color-selago);
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &,
  div {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    background-color: var(--primary-button-color);

    div {
      background-color: var(--color-white);
    }
  }

  div {
    margin-left: 3px;
    border-radius: 50%;
    height: 4px;
    width: 4px;
    background-color: var(--primary-button-color);

    &:first-child {
      margin-left: 0;
    }
  }
}

.MoreModalPaper {
  box-shadow: 10px 10px 40px 0 var(--color-mystic);
  border-radius: 6px;
  width: 200px;
  padding: 10px 0;
  position: relative;
  overflow: visible;
  overflow-x: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: var(--color-white);
    top: -7px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
}

.MoreModalItem {
  all: unset;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  width: 200px;
  max-width: 200px;

  &:hover {
    background-color: #f2f2ff;
  }

  &:first-child svg path {
    fill: var(--color-black);
  }

  svg,
  path {
    width: 15px;
    height: 15px;
  }

  span {
    font-size: 11px;
    font-family: var(--font-poppins);
    color: var(--primary-text-color);
    margin-left: 13px;
  }
}

.DeleteDialogText {
  color: var(--primary-text-error-color) !important;

  span {
    color: var(--primary-text-error-color) !important;
  }
}

.Avatar {
  width: 60px;
  height: 60px;

  @include media-xs {
    width: 40px;
    height: 40px;
  }
}

.ImageTitlesWrapper {
  display: flex;
}
.PhoneSelectWrapper {
  display: flex;
  flex-flow: column;

  a {
    cursor: pointer;
    color: var(--primary-text-color);
    font-size: 13px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 20px;

    &:hover {
      transition: 0.3s;
      background-color: #f7f8f9;
    }
  }
}
.PhonesModalPaper {
  width: auto;
  padding: 10px 15px;
}
