.HeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  & > div {
    margin-bottom: 0;
    padding: 0;
  }
}

.Table {
  thead {
    background-color: #f5f6fa;

    & > tr th {
      height: 36px;
      padding: 7px 28px;
      color: #8387a5;
      font-size: 15px;
      font-weight: 300;
      font-family: var(--font-poppins);
      border-bottom: none;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  tbody {
    margin-top: 34px;

    & > tr td {
      font-size: 17px;
      border: none;
      color: #001847;
      font-weight: 300;
    }
  }
}

.Badge {
  font-size: 13px;
  background-color: rgba(131, 135, 165, 0.1);
  padding: 3px 17px;
  border-radius: 4px;
  width: max-content;
}

.AddPopup {
  width: 360px;
  border-radius: 10px;
  box-shadow: 10px 10px 40px 0 #d8dfea;
  padding: 7px 7px 15px;
}

.Input {
  width: 100%;
}

.ButtonWrapper {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 15px;
    }
  }
}
