.hiddenInput {
  display: none;
}
.input {
  background: none;
  border: none;
  font-size: 15px;
  padding: 0;
  color: var(--primary-text-color);
}
