.SaveContactButton {
  color: var(--secondary-button-color);
  background-color: var(--primary-button-color);
  font-size: 15px;
  font-weight: 500;
  border-radius: 20px;
  padding: 7px 50px;
  text-transform: capitalize;
}

.SaveContactButton:hover {
  background-color: var(--primary-button-color);
}

.SaveContactButton:focus {
  background-color: var(--primary-button-color);
}

.addFieldButton {
  font-family: inherit;
  font-size: 13px;
  line-height: 1.54;
  font-weight: normal;
  color: var(--primary-button-color);
  border: 0;
  background-color: transparent;
  display: inline-block;
}
