.item {
  display: flex;
  align-items: center;
  font-size: 11px;
}
.label {
  width: 100%;
}

.name {
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 158px;
}
.container {
  margin-top: 10px;
  border-radius: 12px;
  box-shadow: 10px 10px 40px 0 #d8dfea;
  max-height: 400px;
  width: 250px;
}
.container::before {
  position: absolute;
  content: ' ';
  top: 0;
  width: 20px;
  background-color: #fff;
  left: 0;
  height: 20px;
}
.container fieldset {
  width: 100%;
}

.searchInput {
  background-color: #f5f5f8;
  border-radius: 6px;
  margin: 10px;
}
.searchInput input {
  padding: 9px 14px 8px 14px;
  font-size: 13px;
  color: var(--primary-text-color);
}
.searchInput input::placeholder {
  color: #8387a5;
}
.InputAdornment {
  margin-right: 15px;
}
.InputAdornment svg {
  width: 14px;
  height: 14px;
  fill: var(--primary-text-color);
}
.checkbox.checked {
  color: #5f48e9;
  border-radius: 3px;
}
.checkbox {
  color: rgba(0, 24, 71, 0.3);
}
.contactContainer {
  margin-right: 0;
  margin-left: 0;
  padding-left: 16px;
  transition: 0.25s background-color;
  max-width: 250px;
}
.contactContainer:hover {
  background-color: #f2f2ff;
}

.disabledContact {
  padding: 9px 9px 9px 16px;
}

.disabledItem .name {
  opacity: 0.3;
}
