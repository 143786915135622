.colorIconButton {
  padding: 0;
}

.colorWrapper {
  padding: 25px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--primary-text-color);
}
.title svg {
  margin-right: 8px;
}

.colorPalette {
  margin-top: 10px;
  display: flex;
  flex-flow: row wrap;
  max-width: 255px;
}

.colorPalette .colorPaletteItem {
  border: 0;
  width: 30px;
  height: 30px;
  margin-top: 15px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 5px 0 rgba(63, 81, 180, 0.25);
}

.colorPalette .colorPaletteItem svg {
  color: #fff;
}

.colorPalette .colorPaletteItem:not(:nth-child(6n + 1)) {
  margin-left: 15px;
}

.ColorActions {
  margin-top: 30px;
}

.saveButton {
  padding-left: 24px;
  padding-right: 24px;
  margin-right: 16px;
}
