.sectionWrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}

.listContainer {
  margin-top: 15px;
}

.taskItem {
  border-radius: 6px;
  border: solid 1px #eff3f7;
  padding: 14px 16px;
  margin-bottom: 10px;
}

.taskTopContent {
  display: flex;
  align-items: flex-start;
  border-bottom: solid 1px rgb(131, 135, 165, 0.23);
  padding: 0 0 8px;
}

.status {
  margin-top: 2px;
}

.taskMainInfo {
  margin-left: 15px;
}

.taskTitle {
  color: var(--primary-text-color);
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.6px;
  display: flex;
  align-items: center;
}

.taskImportance {
  margin-left: 10px;
}

.taskBadges {
  margin: 10px 0 8px;
  display: flex;
}

.badge {
  margin-right: 6px;
}

.taskDescription {
  font-size: 13px;
  color: #8b90a3;
  margin: 0 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.taskBottomContent {
  margin-top: 10px;
}

.date {
  color: #8387a5;
  font-size: 11px;
  margin-right: 20px;
}

.taskAction:not(:disabled):hover {
  color: #5f48e9;
}

.taskAction {
  transition: 0.3s;
  color: #8387a5;
  font-size: 11px;
  border: none;
  text-transform: uppercase;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.taskAction:disabled {
  color: #c4c3c5;
  cursor: default;
}

.taskCheckbox {
  color: #c4c3c5;
  width: 20px;
  height: 20px;
}
.taskCheckbox:global(.Mui-checked) {
  color: #fff;
  background-color: #19e35f;
}
.taskImportant {
  color: #8387a5;
  margin-left: 10px;
  padding: 0;
}
.taskImportantTrue {
  color: #6c04e4;
}
