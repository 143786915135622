.wrapper {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: inherit;
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.button svg {
  height: 100%;
  width: 100%;
  transition: opacity 0.25s, fill 0.25s, color 0.25s;
}

.button .isImportant {
  color: #6c04e4;
  fill: #6c04e4;
}

.button:not(:disabled) .isImportant:hover {
  opacity: 0.5;
}

.button .isNotImportant {
  color: #8387a5;
  fill: #8387a5;
}

.button:not(:disabled) .isNotImportant:hover {
  color: #6c04e4;
  fill: #6c04e4;
}

.button:disabled {
  opacity: 0.6;
  cursor: default;
}
