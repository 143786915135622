.selectsWrapper {
  display: flex;
  margin-top: 30px;
}
.statusButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 10px;
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 120px;
  font-size: 12px;
  text-transform: capitalize;
  color: var(--primary-text-color);
}
.AssignSelect {
  width: 120px;
  & > button {
    width: 100%;
    height: 100%;
    padding-left: 10px;
  }
}
.buttonIconArrow {
  margin-left: 5px;
  width: 9px;
  fill: var(--primary-text-color);
}

.buttonIconArrow.menuOpen {
  transform: scale(1, -1);
}
