.Area {
  border-radius: 6px;
  height: 65px;
  width: 100%;
  border: 1px dashed var(--primary-border-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 100ms;

  &.DragActive {
    border-color: var(--primary-text-color);
    background: var(--primary-border-color);
  }
}

.TextInstruction,
.DnDAreaDescription {
  font-family: var(--font-poppins);
  text-align: center;
}

.TextInstruction {
  font-size: 13px;
  color: var(--primary-text-color);

  span {
    color: var(--primary-button-color);

    &:hover {
      text-decoration: underline;
    }
  }
}

.DnDAreaDescription {
  font-size: 11px;
  color: var(--color-manatee);
  margin-top: 3px;

  &.DragDescription {
    font-size: 15px;
    color: var(--primary-text-color);
  }
}

.AddButton {
  position: relative;
  cursor: pointer;
  margin-top: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }

  input {
    z-index: 1;
  }

  * {
    cursor: pointer !important;
  }
}
