.wrapper {
  user-select: none;
  width: 100%;
  border-radius: 8px;
  transform: none !important;
  background-color: #fff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s;
  max-width: 285px;
  margin: 10px auto;
}

.wrapper:hover {
  background-color: #f2f2ff;
}
