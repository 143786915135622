.section {
  padding: 0 0 30px;
}

.list {
  margin: 20px 0 10px;
  padding: 0;
  min-width: 600px;
}

.listItem {
  list-style-type: none;
}

.listItem:not(:last-child) {
  margin-bottom: 16px;
}
