.DialogItem {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  cursor: pointer;
  font-size: 12px;
  color: var(--primary-text-color);
  position: relative;

  &:hover {
    background-color: var(--color-selago);
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: 14px;
  }

  &.IsExpanding {
    &:after {
      content: '';
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 6px;
      height: 6px;
      border: 1px solid var(--primary-text-color);
      border-left: none;
      border-bottom: none;
    }
  }

  &.Red {
    color: var(--color-torch-red);
  }
}
