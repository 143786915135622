.Item {
  border: 1px solid var(--primary-border-color);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  max-height: 72px;
  min-height: 72px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.Actions {
  width: 26px;
  display: flex;
  justify-content: space-between;

  &.ActionsDisabled {
    svg:hover {
      fill: var(--color-manatee);
    }
  }

  &.ActionsLoading {
    justify-content: center;
  }

  svg {
    max-width: 16px;
    fill: var(--color-manatee);
    cursor: pointer;

    &:hover {
      fill: var(--primary-button-color);
    }
  }
}

.ActionWrapper {
  width: 16px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-right: 0;
  }
}

.ProgressWrapper {
  width: 15px;
  height: 15px;
  cursor: default;
  margin-right: 12px;

  svg {
    max-width: unset;
  }
}

.DownloadButton {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MoreButton {
  all: unset;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  div {
    width: 4px;
    height: 4px;
    background: var(--primary-button-color);
    margin-left: 5px;
    border-radius: 50%;

    &:first-child {
      margin-left: 0;
    }
  }
}

.FilesMenu {
  border-radius: 6px;
  width: 163px;
  padding: 8px 0;
}

.MenuButton {
  all: unset;
  display: flex;
  align-items: center;
  padding: 8px 0 8px 19px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: var(--color-titan-white);
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: 12px;
    fill: var(--primary-button-color);
  }

  span {
    font-family: var(--font-poppins-regular);
    font-size: 11px;
    color: var(--primary-text-color);
  }
}
