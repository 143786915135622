.wrapper {
  display: flex;
  margin-left: 30px;
}
.dialogButton {
  background-color: #8387a5;
  color: #fff;
  font-size: 13px;
  background-color: #8387a5;
  border: 2px solid #fff;
  outline: none;
  width: 32px;
  height: 32px;
  margin-left: -10px;
  border-radius: 50%;
  transition: 0.2s;
  &:hover {
    z-index: 2;
    transform: translateY(-5px);
  }
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 50%;
  width: 32px;
  transition: 0.2s;
  height: 32px;
  &:hover {
    z-index: 2;
    transform: translateY(-5px);
  }
  &:not(:first-child) {
    margin-left: -10px;
  }
  .avatarImage {
    background-color: rgb(108, 32, 228);
    height: 30px;
    width: 30px;
    border: 1px solid #fff;
  }
  &.selectedAvatar {
    border: 1px solid #5f48e9;
  }
}
