.ListItem {
  padding: 10px 15px;
  border: solid 1px #eff3f7;
  border-radius: 6px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ContentWrapper {
  position: relative;
  overflow: hidden;
}

.NoteContent {
  color: var(--primary-text-color);
  font-size: 13px;
  margin: 0;
  white-space: pre-wrap;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;

  &.Expanded {
    height: unset;
  }

  &.Collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}

.AdditionalInfo {
  color: #8387a5;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid var(--color-manatee-a23);
  padding-top: 10px;
  width: 100%;
}

.NoteUserAvatar {
  display: inline-flex;
  margin-right: 8px;
  vertical-align: middle;
}

.NoteActions {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.ActionButton {
  align-self: center;
  height: 20px;
  width: 20px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  margin: 0 0 0 20px;
  padding: 0;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--secondary-text-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .ButtonIcon {
    fill: var(--secondary-text-color);
    display: block;
  }
}

.EditAction .ButtonIcon {
  width: 18px;
}

.DeleteAction .ButtonIcon {
  width: 13px;
}

.CollapseButton {
  all: unset;
  display: block;
  cursor: pointer;
  font-family: var(--font-poppins-regular);
  font-size: 13px;
  text-decoration: underline;
  color: var(--primary-button-color);
  transition: color 100ms ease;
  line-height: 20px;
  background-color: var(--color-white);
  margin-left: auto;

  &:hover {
    color: var(--color-perfume);
  }
}
