.Button {
  cursor: pointer;
  border-radius: 3px;
  border: 1.5px solid var(--primary-text-color);
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background: transparent;
  transition: all 200ms;

  &:hover {
    background: var(--primary-text-color);

    span {
      color: var(--color-white);
    }

    svg,
    svg path {
      fill: var(--color-white) !important;
    }
  }

  span {
    font-size: 13px;
    font-family: Poppins, sans-serif;
    color: var(--primary-text-color);
    transition: all 200ms;
  }

  svg {
    margin-right: 10px;
  }
}
