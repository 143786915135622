.wrapper {
  padding: 50px 0 20px;
  height: 100%;
  overflow-x: scroll;
}

.wrapper > div {
  display: block !important;
  width: 856px;
}
