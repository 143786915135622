.title {
  font-size: 22px;
  color: var(--primary-text-color);
  font-weight: 500;
  margin: 30px 0 20px;
}
.wrapper {
  padding-bottom: 20px;
}
.relatedContactWrapper {
  margin-left: -15px;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    background-color: #efecfd;
  }
}
